import Link from 'next/link';
import { RecordInterface, Input } from '@components/common/Forms';
import {
  Dispatch, forwardRef, memo, SetStateAction, MouseEvent,
} from 'react';
import { ApiUserTalentItem, ApplicantStatusEnum } from '@type/v1-api-types';
import { CardAnimations } from '@type/card';
import { useTranslation } from 'next-i18next';
import {
  Anchor, Avatar, Button, Card, ImageBj, Modal, RatingScore, Tooltip,
} from '@components/common';
import {
  ChatBubbleBottomCenterIcon,
  ArrowTopRightOnSquareIcon,
  VideoCameraIcon,
  StarIcon as StarIconSolid,
  HandThumbUpIcon,
  HandThumbDownIcon,
} from '@heroicons/react/24/solid';
import {
  EyeIcon,
  EyeSlashIcon,
  MapPinIcon,
  StarIcon as StarIconOutline,
} from '@heroicons/react/24/outline';
import { useTalentCardActions } from '@components/templates/employer/cards-lists/hooks/useTalentCardActions';
import { SendMessageModalContent } from '@components/templates/cv/partials/modals/SendMessageModalContent';
import { clsx } from 'clsx';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { locationPreferencesKeys } from '@utils/resume/job-type-preferences';
import { dataTestId } from '@services/data-test-id';
import {
  ApplicantBadgePill, SetStatusModal, VideoCvBadge, ApplicantInterest,
} from './partials';

// Interface
// ******************************************
export interface TalentCardFormProps {
  record: RecordInterface,
  name: string,
  setSelectAll?: Dispatch<SetStateAction<boolean | undefined>>,
  getSelectedApplicants?: () => string[],
}

interface TalentCardProps {
  cardData: ApiUserTalentItem,
  locale: Locale,
  className?: string,
  animation?: CardAnimations,
  internalAgent?: boolean,
  showActions?: boolean,
  revalidatePage?: (userSlug: string) => void,
  jobSlug?: string,
  applicant?: TalentCardFormProps,
  openTalentModal?: (userSlug: string, as?: string) => void,
  trackingId?: string,
}


/**
 * @description TalentCard component. To be used in the TalentSearch page.
 *
 * - cardData: *ApiUserTalentItem* - The data for the card. Required.
 * - locale - *Locale* - The current app locale.
 * - isCompany: *boolean* - Check whether the authenticated user is company or not. Optional.
 * - className: *string* - Additional classes for the card. Optional.
 * - animation: *CardAnimations* - The animation for the card. Optional. Default is 'rise'.
 * - internalAgent: *boolean* - If true, the card will have a special button. Optional. Default is undefined.
 * - jobSlug: *string* - The job slug. Optional. Default is undefined. Needed for the CV opening in the job context.
 * - applicant: *object* - The object containing the record function and the name of the applicant. Optional. Default is undefined.
 * - openTalentModal: *function* - If provided, the CV will open in a modal. Optional.
 * - revalidatePage: *function* - The function to revalidate the page. Optional. Default is undefined.
 * - showActions: *boolean* - If true, the card will show the actions. Optional. Default is true.
 * - trackingId: *string* - The request id from page in list (each page has a different apiRequestId string). Used for tracking.
 *
 * Applicant object:
 * - applicant.record: *RecordInterface* - The record function from useBjForm. Required.
 * - applicant.name: *string* - The name of the form group. Required.
 * - applicant.setSelectAll: *Dispatch<SetStateAction<boolean | undefined>>* - The function to set the 'select all' checkbox. Optional. Default is undefined.
 */
export const TalentCard = forwardRef<HTMLDivElement, TalentCardProps>((props, ref) => {
  // Destructure props
  const {
    cardData,
    locale,
    className,
    animation = 'rise',
    internalAgent,
    showActions = true,
    revalidatePage,
    jobSlug,
    applicant,
    openTalentModal,
    trackingId,
  } = props;

  const {
    record,
    name,
    setSelectAll,
    getSelectedApplicants,
  } = applicant || {};

  // Translation
  const { t } = useTranslation('common');

  // Helper variables
  // *****************************************
  const leadExperience = cardData?.leadExperience?.title;
  const talentName = cardData?.privateInformation?.name;
  const talentSlug = cardData?.slug || cardData.id || '';
  const talentCity = (cardData?.location)?.split(', ')[0];
  const talentPreferences = cardData?.preferences?.filter((item) => item !== t(locationPreferencesKeys.relocation))?.join(', ') || '';
  const availableForRelocation = cardData?.preferences?.some((item) => item === t(locationPreferencesKeys.relocation));
  const hasVideoCv = !!cardData?.privateInformation?.videoUrl;
  const spokenLanguages = cardData?.spokenLanguages;
  const cardLinkUrlParams = trackingId ? `?rid=${trackingId}` : '';
  const cardLink = jobSlug ? `/job/${jobSlug}/cv/${talentSlug}${cardLinkUrlParams}` : `/cv/${talentSlug}${cardLinkUrlParams}`;
  const isAddedToShortlist = (!jobSlug && cardData?.interviewFolder?.some((item) => (item.statusId as ApplicantStatusEnum) === ApplicantStatusEnum.SHORTLIST))
    || (jobSlug && (cardData.application?.statusId as ApplicantStatusEnum) === ApplicantStatusEnum.SHORTLIST);
  const { averageRating = 0, reviewsCount = 0 } = cardData?.talentRatingData ?? {};


  // Common classes
  // *****************************************
  const cardClasses = clsx(
    'group/talentCard relative overflow-hidden rounded-md border border-input bg-surface-100 !shadow-none',
    className,
    {
      'border-y-2 border-x border-pink': cardData?.hasUserSubscription,
      '!border-none shadow-xs !bg-surface': cardData?.application?.isNew,
      '!bg-surface !border-none': !cardData?.application,
    },
  );
  const titleClasses = 'mt-1 mb-2.5 font-bold leading-tight text-lg';
  const cvCompletionBadgeClasses = '!bg-secondary text-white !outline-surface !ring-surface !bottom-auto top-0 !-left-1';
  const cardFooterClasses = 'relative z-2 can-hover:h-0 can-hover:transition-all can-hover:duration-200 can-hover:opacity-0 group-hover/talentCard:opacity-100 group-hover/talentCard:-translate-y-13';
  const cardActionWrapperClasses = `h-13 bg-surface-50 grid items-center divide-x divide-input ${cardData?.online ? 'grid-cols-3' : 'grid-cols-2'}`;
  const cardActionButtonClasses = 'group hover:!no-underline hover:enabled:text-ink focus-visible:text-ink';
  const cardActionButtonIconClasses = 'w-5 h-5 mr-2';
  const cardActionButtonIconSecondaryClasses = 'transition-all duration-200 text-primary group-hover:group-enabled:text-primary';

  // Handle the CV opening (normal or in a modal, depending on the props)
  // If we have a jobSlug, we open the CV in the job context
  // *****************************************
  const handleCvOpening = (e: MouseEvent<HTMLAnchorElement>) => {
    if (openTalentModal) {
      e.preventDefault();
      openTalentModal(talentSlug, cardLink);
    }
  };

  const renderIconByScore = (score:number) => {
    if (score === 1) {
      return <HandThumbUpIcon className="size-5 min-w-5 text-secondary" />;
    }
    if (score === 2) {
      return <HandThumbUpIcon className="size-5 min-w-5 text-warning" />;
    }
    return <HandThumbDownIcon className="size-5 min-w-5 text-error" />;
  };

  // Profile operations
  // ***********************************************
  const {
    displaySendMessageModal,
    isLoadingSendMessage,
    toggleSendMessageModal,
    unlockWithMessage,
    isLoadingCallNow,
    isLoadingResumeDetail,
    unlockByCall,
    isOpenStatusModal,
    toggleStatusModal,
    operationJob,
    isLoadingOperationJob,
    saveToShortlistAction,
  } = useTalentCardActions({
    userSlug: cardData?.slug as string,
    locale: locale ?? 'ro',
    revalidatePage: revalidatePage ?? (() => {}),
    hasInterviewFolder: !!cardData?.hasInterviewFolder,
    interviewFolders: cardData?.interviewFolder,
    rid: trackingId,
  });

  // Render component
  // *****************************************
  return (
    <Card ref={ref} animation={animation} className={cardClasses} {...dataTestId('cvSearchPage.card.default')}>
      <Card.Body className="relative flex gap-4 !p-4">
        {/* CARD LINK */
          talentSlug && (
            <Link
              href={cardLink}
              prefetch={false}
              onClick={handleCvOpening}
              className="absolute inset-0 z-1 flex"
            >
              {/* Tooltip for premium users on talent list (no job context) */
                cardData?.hasUserSubscription && !jobSlug ? (
                  <Tooltip
                    tooltipText={t('premium.cv.badge.tooltip')}
                    position="top"
                    floating
                    className="block size-full"
                    triggerContainerClass="block w-full h-full"
                    colorScheme="pink"
                  >
                    <span className="opacity-0">
                      {leadExperience || talentName}
                    </span>
                  </Tooltip>
                ) : (
                  <span className="opacity-0">
                    {leadExperience || talentName}
                  </span>
                )
              }
            </Link>
          )
        }

        {/* APPLICANT checkbox; uncheck 'select all' checkbox on click */
          record && name && (
            <label
              htmlFor="applicantSlug"
              aria-label={t('global.default.select.option')}
              title={t('global.default.select.option')}
              className="absolute -top-1 right-0 z-2 cursor-copy p-1"
            >
              <Input
                defaultValue={cardData?.slug}
                type="checkbox"
                className="cursor-copy text-secondary ring-3 ring-surface checked:bg-secondary"
                {...record(name, {
                  id: `${name}_${cardData?.slug}`,
                  valueAsArray: true,
                })}
                onClick={() => {
                  if (setSelectAll) setSelectAll(undefined);
                  if (getSelectedApplicants) getSelectedApplicants();
                }}
              />
            </label>
          )
        }

        {/* DESCRIPTION */}
        <div className="grow">
          {(internalAgent || cardData?.activityStatus || cardData?.interest) && (
            <div className="mb-2 flex items-center gap-3 divide-x divide-ink/15 text-sm text-ink-light">
              {/* Activity status; hide it in applicants lists */
                !jobSlug && cardData?.activityStatus && (
                  <span className="flex shrink-0 items-center gap-2">
                    <span className="inline-block size-2.5 rounded-full bg-secondary" />
                    {cardData?.activityStatus}
                  </span>
                )
              }

              {/* Interest (ie: applied at date); show only in job context */
                jobSlug && cardData?.interest && (
                  <ApplicantInterest type={cardData.interest?.type} value={cardData.interest?.value} />
                )
              }

              {/* Internal agent button */
                internalAgent && cardData?.id && (
                  <span {...cardData?.activityStatus || cardData?.interest ? { className: 'pl-3' } : {}}>
                    <Anchor
                      href={`https://intranet.bestjobs.eu/user/detail/${cardData?.id}`}
                      styling="text"
                      size="sm"
                      color="primary-light"
                      rounding="none"
                      aria-label="internal-agent"
                      title="internal-agent"
                      className="relative z-2"
                      target="_blank"
                    >
                      <ArrowTopRightOnSquareIcon className="size-4.5" />
                    </Anchor>
                  </span>
                )
              }

              {cardData?.application && (
                cardData.application.isNew ? (
                  <EyeSlashIcon className="size-4 !border-0" />
                ) : (
                  <EyeIcon className="size-4 !border-0" />
                )
              )}
            </div>
          )}

          {/* Main title of the card: Lead experience or name (if CV is unlocked) */
            (talentName || leadExperience) && (
              <div className={titleClasses}>{talentName || leadExperience}</div>
            )
          }

          {/* The rest of the description */}
          <div className="text-sm text-ink-medium">
            {/* Lead experience if CV unlocked - that means the title is the name */
              talentName && leadExperience && (
                <div className="font-bold">{leadExperience}</div>
              )
            }
            {/* Lead experience company */
              cardData?.leadExperience?.company && (
                <div>{`@ ${cardData?.leadExperience?.company}`}</div>
              )
            }
            {/* Lead experience period */
              cardData?.leadExperience?.period && (
                <div>
                  {`${t('card.experience.total.domain')} `}
                  <strong>{cardData?.leadExperience?.period}</strong>
                </div>
              )
            }
            {/* Location (city only) and preferences */
              (talentCity || talentPreferences) && (
                <div className="mb-0.5 mt-2.5 flex gap-1 text-xs">
                  <MapPinIcon className="mt-px size-4 shrink-0" />
                  <span className="line-clamp-2 grow">
                    {talentCity}
                    {availableForRelocation && (
                    <>
                      {', '}
                      <strong>
                        {t(locationPreferencesKeys.relocation)}
                      </strong>
                    </>
                    )}
                    {talentPreferences && ', '}
                    {talentPreferences}
                  </span>
                </div>
              )
            }
          </div>
        </div>

        {/* AVATAR - make room for buck checkbox when in job context */}
        <div className="shrink-0">
          {/* Talent avatar */}
          <div className="relative">
            {/* Badge for 100% Cv completion */
              cardData?.cvCompletion === 100 && (
                <Avatar.Badge withRing iconName="star" className={cvCompletionBadgeClasses} />
              )
            }
            {/* Video CV badge */
              hasVideoCv && (
                <VideoCvBadge />
              )
            }
            <Avatar
              imgPath={cardData?.imgUrl}
              imgFolder="resume_image"
              extractImageName
              className={`mx-auto ${hasVideoCv ? '!outline-2 !outline-offset-3 !outline-secondary' : ''}`}
              alt={talentName}
              name={talentName}
              withRing={hasVideoCv}
            />

          </div>

          {averageRating > 0 && reviewsCount > 0
            && (
              <div className="mt-3">
                <RatingScore className="size-4 text-warning" score={averageRating} numberOfRatings={reviewsCount} showScore={false} />
              </div>
            )}

          {/* Language flags */
            spokenLanguages && spokenLanguages.length && (
              <div className="mt-1.5 flex items-center justify-center gap-1.5">
                {spokenLanguages.slice(0, 2).map((lang) => (
                  <ImageBj
                    key={`${lang.shortName}-${cardData.slug}-${cardData?.id}`}
                    src={lang.flagUrl || ''}
                    alt=""
                    width={16}
                    height={16}
                    className="size-4 shrink-0"
                  />
                ))}
                {/* Show if we have more than 2 languages */
                  spokenLanguages.length > 2 && (
                    <span className="text-xs text-ink-medium">{`+${spokenLanguages.length - 2}`}</span>
                  )
                }
              </div>
            )
          }

          {/* Application status */
            cardData?.application && (
              <div className="mt-2 max-w-24 truncate text-center">
                {!cardData?.hideStatus && <ApplicantBadgePill statusId={cardData.application?.statusId} />}
              </div>
            )
          }
        </div>

      </Card.Body>

      {/* Candidate Evaluation */}
      {
        cardData?.application?.evaluation && (
          <div className="px-3 pb-3">
            <div className="flex items-start gap-2 rounded-md bg-surface-50 p-2">
              {renderIconByScore(cardData?.application?.evaluation?.status ?? 3)}
              <div className="line-clamp-3 text-tiny font-thin">
                {cardData?.application?.evaluation?.summary}
              </div>
            </div>
          </div>
        )
      }

      {/* CARD ACTIONS */}
      {
        (showActions) && (
          <Card.Footer className={cardFooterClasses}>
            <div className={cardActionWrapperClasses}>
              {/* Save to shortlist */}
              <Button
                styling="text"
                size="sm"
                color="ink-medium"
                rounding="none"
                className={cardActionButtonClasses}
                isLoading={isLoadingOperationJob}
                onClick={saveToShortlistAction}
                {...dataTestId('cvSearchPage.card.saveToShortlistAction')}
              >
                {!isLoadingOperationJob && (
                  isAddedToShortlist
                    ? <StarIconSolid className={clsx(cardActionButtonIconClasses, cardActionButtonIconSecondaryClasses)} />
                    : <StarIconOutline className={clsx(cardActionButtonIconClasses, cardActionButtonIconSecondaryClasses, 'stroke-2')} />
                )}
                <span>{t('talent-card.v2.action.shortlist')}</span>
              </Button>

              {/* Call 1-1 action - only for online users */
                cardData?.online && (
                  <Button
                    styling="text"
                    size="sm"
                    color="ink-medium"
                    rounding="none"
                    className={cardActionButtonClasses}
                    isLoading={isLoadingCallNow || isLoadingResumeDetail}
                    disabled={isLoadingCallNow || isLoadingResumeDetail || !cardData.slug}
                    onClick={unlockByCall}
                    {...dataTestId('cvSearchPage.card.unlockByCall')}
                  >
                    {
                      !isLoadingCallNow
                      && !isLoadingResumeDetail
                      && <VideoCameraIcon className={clsx(cardActionButtonIconClasses, cardActionButtonIconSecondaryClasses)} />
                    }
                    <span>{t('talent-card.v2.action.call')}</span>
                  </Button>
                )
              }

              {/* Send Message action */}
              <Button
                styling="text"
                size="sm"
                color="ink-medium"
                rounding="none"
                className={cardActionButtonClasses}
                isLoading={isLoadingSendMessage}
                disabled={isLoadingSendMessage || !cardData.slug}
                onClick={unlockWithMessage}
                {...dataTestId('cvSearchPage.card.unlockWithMessage')}
              >
                {!isLoadingSendMessage && <ChatBubbleBottomCenterIcon className={cardActionButtonIconClasses} />}
                <span>{t('talent-card.v2.action.message')}</span>
              </Button>

            </div>
          </Card.Footer>
        )
      }

      {/* Set talent status modal */}
      {cardData && cardData.slug && isOpenStatusModal && (
        <SetStatusModal
          isOpen={isOpenStatusModal}
          locale={locale}
          operationJob={operationJob}
          userSlug={cardData.slug}
          interviewFolders={cardData.interviewFolder}
          onClose={(isSuccess) => {
            toggleStatusModal();

            if (isSuccess) {
              // Send Google Tag Manager event
              sendGoogleTagEvent('cv_list_set_shortlist', 'Custom');

              if (revalidatePage) { revalidatePage(cardData.slug as string); }
            }
          }}
          trackingId={trackingId}
        />
      )}

      {/* Send message modal */}
      {cardData && cardData.slug && (
        <Modal
          isOpen={displaySendMessageModal}
          body={(
            <SendMessageModalContent
              imageUrl={cardData.imgUrl || ''}
              candidateName={cardData?.privateInformation?.name || ''}
              onSendSuccess={() => toggleSendMessageModal()}
              onClose={() => { toggleSendMessageModal(); }}
              slug={cardData.slug}
              trackingId={trackingId}
              locale={locale}
            />
          )}
          onClose={() => { toggleSendMessageModal(); }}
        />
      )}
    </Card>
  );
});


/**
 * @description Memoized TalentCard component. To be used in the infinite lists.
 */
export const TalentCardMemo = memo(TalentCard);
